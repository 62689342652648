<template>
	<div id="detail">
		<headers :whiteFlg="true" :activeItem="1" />
		<div class="have-top-nav"></div>
		<div class="container">
			<div class="card-note">
				<div class="left-card">
					<el-carousel class="carousel">
						<el-carousel-item v-for="item in data.recoveryProductListimg" :key="item" style="height: 100%;">
							<div class="img" :style="{backgroundImage:'url('+item+')'}">
							</div>
						</el-carousel-item>
					</el-carousel>
					<main>
						<div class="content">
							{{data.recoveryProductDetail}}
						</div>
					</main>

					<div class="all-tip">
						<h3 class="top-tit"><i class="remain"></i> <span class="brief">用户/回收商估价</span></h3>
						<div class="content" v-if="data.orderList">
							<div class="comment" v-for="(item ,index) in data.orderList" :key="index">
								<div class="comment-info">
									<div class="user">
										<div class="avatar avatar avatar-XS type-outline">
											<div class="avatar-img cube-image normal-image">
												<img :alt="item.userNickname" :src="item.userHeadimg" class="img">
												<img src="../assets/vip.png" class="crown" v-if="item.userRoleType==1">
											</div>
										</div>
										<div class="user-info">
											<h4 class="user-nickname">{{item.userNickname}}</h4>
											<p class="xinyu"><i></i>信誉值{{item.creditScore}}分 </p>
										</div>
									</div>
									<div class="comment-stats"><span
											class="reply-action">已{{item.userRoleType==0?'出':'估'}}价</span></div>
								</div>
							</div>
							<div class="bottom" @click="popShowFlg = true">查看更多</div>
						</div>
						<div v-else class="f16 text-center c99 mt40">
							感兴趣吗？快去出个价吧！
						</div>
					</div>
				</div>
				<div class="right-card" @click="popShowFlg = true">
					<h3 class="top-tit visible-sm visible-xs"><i class="remain"></i> <span class="brief">信息发布者</span></h3>
					<div class="author-item bottom-gap">
						<h3 class="title hidden-sm hidden-xs">信息发布者</h3>
						<span class="author-info">
							<div class="left">
								<div class="left-img"><img :src="data.releasedUserHeadimg"
										style="width: 2.5rem; height: 2.5rem;"> <i class="border-img"
										style="width: 2.5rem; height: 2.5rem;"></i></div>
							</div>
							<div class="right" style="width: 9.5rem;">
								<h6 class="name f16">
									<span class="name-detail">{{data.releasedUserNickname}}</span>
								</h6>
								<p class="xinyu"><i></i>信誉值{{data.releasedCreditScore}}分 </p>
							</div>
						</span>
						<div class="flex d-s-comment">
							<div class="blk">
								<span class="f14">好评率</span>
								<span class="f18">{{data.praiseRate}}%</span>
								<span class="f14 c99">2条评论</span>
							</div>
							<div class="blk">
								<span class="f14">信誉分</span>
								<span class="f18">{{data.releasedCreditScore}}</span>
								<span class="f14 c99">{{data.userCreditScoreNumber}}条信誉记录</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pop-over-container pop-over" v-if="popShowFlg" @click="popShowFlg = false">
				<div class="download-app"><i class="sweet-potato"></i>
					<div class="image-box"><img src="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/xcx-.jpg">
					</div>
					<p>扫码进入么丢</p>
					<p>与全世界的么友们一起进入低碳生活</p>
					<div class="closeTxt" @click="popShowFlg = false">关闭</div>
				</div>
			</div>
		</div>
		<div class="have-top-nav"></div>

		<footers :bottom="true" />
	</div>
</template>

<script>
	const axios = require('axios');
	import headers from "./components/headers.vue";
	import footers from "./components/footers.vue";
	export default {
		name: "list",
		components: {
			headers,
			footers
		},
		data() {
			return {
				popShowFlg: false,
				data: ""
			}
		},
		mounted() {
			this.requestData()
		},
		methods: {
			requestData() {
				axios.post('/appOfficialWebsite/appOfficialWebsiteProDetailApi', {
						recoveryProductId: this.$route.query.recoveryProductId
					})
					.then((res) => {
						this.data = res.data.data;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
		}
	}
</script>

<style scoped>
	.card-note {
		width: 42.5rem;
		margin: 2.5rem auto 0;
		padding-bottom: 1.8rem;
		display: flex;
		justify-content: space-between;
	}

	.card-note .left-card {
		/* float: left; */
		margin: 1rem 0 0;
	}

	.card-note .bottom-gap {
		margin: 0 0 1rem;
	}

	.carousel {
		position: relative;
		background: #fbfbfb;
		overflow: hidden;
		border-radius: 0.2rem;
		width: 25rem;
		height: 25rem;
		margin-bottom: 2rem;
	}

	.carousel .img {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		width: 100%;
		height: 100%;
	}

	/deep/.el-carousel__container {
		height: 100% !important;
	}

	.content {
		font-size: 0.8rem;
		line-height: 1.75;
	}

	.all-tip {
		font-size: 0.7rem;
		color: #000;
		overflow: hidden;
		margin-top: 2rem;
	}

	.top-tit {
		font-size: 0.8rem;
		margin: 0 0 1rem;
		font-weight: bold;
	}

	.top-tit .remain {
		width: 0.25rem;
		height: 0.8rem;
		display: inline-block;
		background: #1F4D0D;
		vertical-align: middle;
		margin: 0 0.35rem 0 0;
	}

	.top-tit .brief {
		vertical-align: middle;
	}

	.comment {
		position: relative;
		padding: 0.75rem 0.75rem 0.75rem 0;
		margin-left: 0.75rem;
	}

	.all-tip .comment {
		padding: 1rem 0;
		border-bottom: 1px solid #eee;
	}

	.all-tip .comment:first-child {
		padding: 0 0 1rem;
	}

	.comment .comment-info {
		overflow: hidden;
		position: relative;
	}

	.avatar.type-outline {
		position: relative;
		border: none;
	}

	.user .avatar,
	.user {
		float: left;
	}

	.avatar.avatar-XS {
		width: 2.25rem;
		height: 2.25rem;
	}

	.avatar.type-outline:before {
		content: "";
		position: absolute;
		width: 200%;
		height: 200%;
		top: 0;
		left: 0;
		transform-origin: 0 0;
		transform: scale(.5);
		box-sizing: border-box;
		border: 1px solid #e6e6e6;
		border-radius: 50%;
	}

	.avatar .avatar-img {
		display: block;
		width: 100%;
		height: 100%;
		font-size: 0;
		overflow: hidden;
		z-index: 10;
		/* mask-image: radial-gradient(#fff, #000); */
		/* -webkit-mask-image: -webkit-radial-gradient(#fff, #000); */
	}

	.cube-image .img {
		opacity: 1;
		transition: opacity .2s ease-in;
		border-radius: 50%;
	}

	.crown {
		position: absolute;
		width: .75rem;
		height: .75rem;
		z-index: 1;
		right: 0;
		bottom: 0;
	}

	.cube-image.normal-image>.img {
		display: block;
		width: 100%;
		height: auto;
	}

	.user .user-info {
		float: left;
		margin-left: 0.5rem;
	}

	.user .user-nickname {
		margin: 0;
		font-size: 0.8rem;
		line-height: 1rem;
		color: #333;
	}

	.user .user-nickname a {
		text-decoration: none;
		color: #333;
	}

	.user .publish-time {
		display: inline-block;
		margin-top: 0.25rem;
		font-size: 0.6rem;
		line-height: 0.7rem;
		color: #999;
	}

	.comment-stats {
		float: right;
		font-size: 0.8rem;
		line-height: 0.8rem;
		font-weight: bold;
		color: #1F4D0D;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.card-note .right-card {
		/* float: right; */
		width: 16rem;
		margin-top: 1rem;
	}

	.author-item {
		width: 16rem;
		/* height: 8.8rem; */
		border: 1px solid #eee;
		border-radius: 0.2rem;
		color: #000;
		font-size: 0.7rem;
		background: #fff;
	}

	.card-note .bottom-gap {
		margin: 0 0 1rem;
	}

	.author-item .title {
		font-weight: 500;
		font-size: 0.8rem;
		padding: 0.5rem 0 0.5rem 1rem;
		margin: 0;
		border-bottom: 1px solid #eee;
	}

	.author-info {
		margin: 0.45rem 0.55rem 0;
		padding: 0 0 0.5rem;
		overflow: hidden;
		font-size: 0.6rem;
		cursor: pointer;
		color: #333;
		display: block;
	}

	.author-item .author-info {
		margin: 0.5rem 1rem 0;
		padding: 0 0 0.5rem;
		overflow: hidden;
		border-bottom: 1px solid #eee;
	}

	.all-tip .bottom {
		text-align: center;
		padding: 1rem 0;
		margin-bottom: 1rem;
		cursor: pointer;
		color: #1F4D0D;
		font-weight: 500;
	}

	.left {
		position: relative;
		float: left;
	}

	.left-img img {
		border-radius: 50%;
	}

	.left-img .border-img {
		border: 1px solid rgba(0, 0, 0, .15);
		display: block;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.right {
		float: left;
		margin: 2px 0 0 1.5rem;
	}

	.name {
		margin: 3px 0 0;
		font-weight: 500;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		height: 1rem;
		overflow: hidden;
		white-space: nowrap;
		word-wrap: normal;
		text-overflow: ellipsis;
	}

	.name .name-detail {
		height: 0.8rem;
		line-height: 0.8rem;
	}

	.xinyu {
		display: inline-block;
		margin-top: 0.25rem;
		border: 1px solid #FF6740;
		border-radius: 0.85rem;
		font-size: 0.6rem;
		color: #FF6740;
		padding: 0.14rem 0.3rem;
		line-height: 1;

	}

	.xinyu i {
		width: .7rem;
		height: .6rem;
		background-image: url("../assets/xinyu.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		display: inline-block;
		margin-right: 0.25rem;
		vertical-align: middle;
	}

	.d-s-comment {
		text-align: center;
		position: relative;
		padding: .5rem 0;
	}

	.d-s-comment .blk {
		flex: 1;
	}

	.d-s-comment span {
		display: block;
		margin: .35rem 0;
	}

	.d-s-comment::before {
		content: "";
		position: absolute;
		width: 1px;
		height: 1.5rem;
		background: #F0F0F0;
		left: 50%;
		margin-left: -.5rem;
		top: 50%;
		margin-top: -.75rem;
	}

	.pop-over {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
	}

	.pop-over-container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, .4);
	}

	.pop-over-container .download-app {
		width: 14rem;
		height: 13.55rem;
		background-color: #fff;
		border-radius: 0.4rem;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		position: relative;
	}

	.pop-over-container .download-app .image-box {
		width: 7rem;
		height: 7rem;
		border-radius: 0.25rem;
	}

	.pop-over-container .download-app .image-box img {
		width: 100%;
		height: 100%;
	}

	.pop-over-container .download-app p {
		font-size: 0.7rem;
		color: #555;
		text-align: center;
		margin: 0 0 0.15rem;
	}

	.pop-over-container .download-app .closeTxt {
		cursor: pointer;
		width: 100%;
		color: #1F4D0D;
		font-size: 0.7rem;
		font-weight: 600;
		text-align: center;
		border-top: 1px solid #eee;
		padding: 0.5rem 0;
		margin: 0.9rem 0 0;
	}
</style>
